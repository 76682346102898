<template>
  <div class="noData" v-if="isShow">
    <img src="../assets/images/noData.png">
    <div class="describe">{{text}}</div>
  </div>
</template>
<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: () => false,
    },
    text: {
      type: String,
      default: () => "",
    }
  }
};
</script>
<style lang="scss" scoped>
.noData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 450px;
  .describe{
      text-align: center;
      height: 70px;
      line-height: 70px;
      font-size: 16px;
      color: #999;
  }
  img{
      width: 170px;
  }
}
</style>