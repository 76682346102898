<template>
    <div class="dailyPositionWrap">
        <Header></Header>
        <topBg>
            <div slot="text" class="textWrap" v-html="$t('oa.dailyPositionTitle1')">
            </div>
        </topBg>
        <div class="topBgView">
            <div class="bgContent">
                <div class="rightRouter">
                    <!-- <div class="childrenRoute">
                        <router-link class="routeItem" :to="item.path" tag="div" v-for="(item,index) in childrenRoute" :class="{active:$route.meta.activeChildren == item.activeChildren}" :key="index">{{item.text}}</router-link>
                    </div> -->
                    <div class="fliterWrap">
                        <!-- <el-select v-model="documentType" placeholder="">
                            <el-option v-for="(option,index) in documentTypeList" :key="index" :label="option.dictLabel" :value="option.dictLabel">
                            </el-option>
                        </el-select> -->
                        <el-select v-model="documentType" placeholder="" v-if="$i18n.locale == 'TC'">
                            <el-option v-for="(option,index) in documentTypeList" :key="index" :label="option.dictLabel" :value="option.dictValue">
                            </el-option>
                        </el-select>
                        <el-select v-model="documentType" placeholder="" v-if="$i18n.locale == 'CN'">
                            <el-option v-for="(option,index) in documentTypeList" :key="index" :label="option.dictLabelCn" :value="option.dictValue">
                            </el-option>
                        </el-select>
                        <el-select v-model="documentType" placeholder="" v-if="$i18n.locale == 'EN'">
                            <el-option v-for="(option,index) in documentTypeList" :key="index" :label="option.dictLabelEn" :value="option.dictValue">
                            </el-option>
                        </el-select>

                        <el-date-picker v-model="filterDate" :editable="false" type="daterange" value-format="yyyy-MM-dd" :start-placeholder="$t('oa.startTime')" :end-placeholder="$t('oa.endTime')">
                        </el-date-picker>
                        <!-- <el-date-picker v-model="filterDate" type="date" value-format="yyyy-MM-dd" style="width:300px;"></el-date-picker> -->
                        <el-button type="primary" class="searchData" @click="getSummaryInfor()" :default-value="['yyyy:MM:dd']"> {{$t('oa.searchNews')}}</el-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="dailyPosition">
            <div class="rightView">
                <!-- <div class="title"><span></span> {{$t("oa.summary")}}</div> -->
                <div class="displayPosList">
                    <div class="displayItem" :class="{active:activePdfindex == index}" @click="ActiveDownLoad(item,index)" v-for="(item,index) in displayConfig.list" :key="index">
                        <div class="left">
                            <img src="../../assets/images/pdf.png">
                            <div>
                                <div class="title">{{item.date}}</div>
                                <div class="describe">{{item.fileName}}</div>
                            </div>
                        </div>
                        <div class="right">
                            <div class="preview downLoad" @click="openPdf(item)">
                                <div class="box">
                                    <div class="el-icon-view icon"></div>
                                </div>
                                <div class="text">{{$t('oa.preview')}}</div>
                            </div>
                            <div class="downLoad" @click="downPDFDocument(item)">
                                <div class="box"><img src="../../assets/images/download_hold.png"></div>
                                <div class="text">{{$t('oa.DocumentDownLoad')}}</div>
                            </div>
                        </div>
                    </div>
                    <noData :isShow="displayConfig.tatol == 0 || displayConfig.tatol == null" :text="$t(`oa.noData`)"></noData>
                </div>
                <div class="pagination" v-show="displayConfig.tatol !=-1&& displayConfig.tatol">
                    <el-pagination layout="total, prev, pager, next" @current-change="changePage" :page-size="displayConfig.limit" :total="displayConfig.tatol" />
                </div>

            </div>

        </div>
    </div>
</template>
<script>
import url from "@/api/url.js";
import noData from "@/components/noData.vue";
import Header from "@/components/header.vue";
import topBg from "@/components/topBg.vue";
export default {
    components: {
        noData,
        Header,
        topBg,
    },
    name: "",
    data() {
        return {
            filterDate: "",
            loading: false,
            dialogVisible: false,
            summaryPDFInforUrl: "",
            activePdfindex: -1,
            documentTypeList: [],
            documentType: "",
            fileUrl: "",
            displayConfig: {
                list: [],
                limit: 6,
                page: 1,
                tatol: -1,
            },
            childrenRoute: [
                {
                    text: "Suumary",
                    path: "/dailyPosition/summary",
                    activeChildren: "6-1",
                },
            ],
        };
    },
    created() {
        this.initLang();
        // this.filterDate = this.setDate();
        this.getDocumentType();
        this.getSummaryInfor();
    },
    watch: {
        "$i18n.locale": function (newVal) {
            this.initLang();
        },
    },
    methods: {
        initLang() {
            this.childrenRoute[0].text = this.$t("oa.summary");
            // this.childrenRoute[1].text = this.$t('oa.closed');
            // this.childrenRoute[2].text = this.$t('oa.open');
        },
        downPDFDocument(e) {
            let token = localStorage.getItem("AUTtoken");
            // window.open(`/oa/fund/info/pdf/download?date=${this.filterDate}&token=${token}`)
            this.fileUrl = e.fileUrl;
            window.open(`/oa/download?fileUrl=${this.fileUrl}&token=${token}`);
            // window.location = this.summaryPDFInforUrl;
        },
        getDocumentType() {
            this.$axios.get(url.documentTypeList).then((res) => {
                this.documentTypeList = res.sysDictData;
                this.documentTypeList.unshift({
                    dictLabel: "全部",
                    dictLabelCn: "全部",
                    dictLabelEn: "All",
                    dictValue: "",
                });
            });
        },

        // getDocumentType() {
        //     this.$axios.get(url.documentTypeList).then((res) => {
        //         this.documentTypeList = res.sysDictData;
        //         this.documentType = res.sysDictData[0].dictLabel;
        //         this.getSummaryInfor();
        //     });
        // },
        changePage(num) {
            this.displayConfig.page = num;
            this.getSummaryInfor();
        },
        ActiveDownLoad(e, idx) {
            this.fileUrl = e.fileUrl;
            if (this.activePdfindex == idx) {
                this.activePdfindex = -1;
            } else {
                this.activePdfindex = idx;
            }
        },
        openPdf(e) {
            window.open(e.fileUrl);
        },
        getSummaryInfor() {
            this.$axios
                .get(url.fundfileinfor, {
                    params: {
                        beginDate: this.filterDate ? this.filterDate[0] : "",
                        endDate: this.filterDate ? this.filterDate[1] : "",
                        page: this.displayConfig.page,
                        limit: this.displayConfig.limit,
                        // dictLabel:this.documentType
                        dictLabel: this.documentType,
                    },
                })
                .then((res) => {
                    this.displayConfig.list = res.page.list;
                    this.displayConfig.tatol = res.page.totalCount;
                });
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/.el-date-table td.today span {
    color: #305A9E;
}
.dailyPositionWrap {
    /deep/.el-pager li.active {
        color: #305A9E;
    }
    .dailyPosition {
        width: 1320px;
        margin: 0 auto;
        display: flex;
        .rightView {
            flex: 1;
            background-color: #fff;
            min-height: 500px;
            .title {
                font-size: 18px;
                font-weight: normal;
                font-weight: bold;
                display: flex;
                align-items: center;
                span {
                    display: inline-block;
                    width: 5px;
                    height: 18px;
                    background-color: #305A9E;
                    margin-right: 15px;
                }
            }
            .displayPosList {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                margin-bottom: 50px;
                .displayItem {
                    width: 49%;
                    height: 110px;
                    background: #ffffff;
                    margin-top: 35px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 30px;
                    cursor: pointer;
                    box-shadow: 0px 0px 12px 1px rgba(100, 100, 100, 0.1);
                    transition: all 0.3s;
                    border-radius: 6px;

                    .left {
                        display: flex;
                        align-items: center;
                        img {
                            transition: all 0.3s;
                            width: 42px;
                            height: 48px;
                            margin-right: 20px;
                        }
                        .title {
                            color: #ce995f;
                            font-weight: normal;
                            font-size: 14px;
                            margin-bottom: 5px;
                        }
                        .describe {
                            color: #000;
                            font-size: 14px;
                            font-weight: bold;
                        }
                    }
                    .right {
                        display: flex;
                        align-items: center;
                    }
                    .downLoad {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        text-align: center;
                        cursor: pointer;
                        transition: color 0.3s linear;
                        margin: 0 10px;
                        .box {
                            height: 30px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                        }
                        img {
                            width: 16px;
                            filter: grayscale(100%);
                        }
                        .icon {
                            font-size: 22px;
                            color: #333;
                        }
                        .text {
                            font-size: 12px;
                        }
                    }
                }

                .displayItem:hover .left {
                    color: #305A9E;
                }
                .displayItem.active .left {
                    color: #305A9E;
                }
            }
        }
    }
    .topBgView {
        width: 1320px;
        height: 65px;
        background-color: #fff;
        transform: translateY(-40px);
        box-shadow: 0 0 20px rgba(100, 100, 100, 0.1);
        border-radius: 10px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        .bgContent {
            width: 1320px;
            margin: 0 auto;
            display: flex;
            .rightRouter {
                flex: 1;
                display: flex;
                justify-content: space-between;
            }
        }

        .childrenRoute {
            display: flex;
            .routeItem {
                line-height: 40px;
                height: 40px;
                font-size: 14px;
                padding: 0 30px;
                cursor: pointer;
                font-weight: bold;
                border: 1px solid #7d7d7d;
                transition: all 0.3s linear;
                position: relative;
                color: #fff;
                margin-left: -1px;
            }
            .routeItem.active {
                color: #305A9E;
                border-color: #305A9E;
                background-color: #305A9E;
                color: #fff;
            }
        }

        .fliterWrap {
            width: 100%;
            padding: 0 100px;
            display: flex;
            /deep/.el-select {
                margin-right: 30px;
                .el-input__inner {
                    border: 2px solid #d9d9d9;
                    border-radius: 30px;
                    height: 35px;
                    font-size: 16px;
                    padding-left: 30px;
                }
            }
            /deep/.el-date-editor .el-range__close-icon {
                font-size: 20px;
                display: flex;
                align-items: center;
            }
            /deep/.el-range-editor.el-input__inner {
                border: 2px solid #d9d9d9;
                flex: 1;
                border-radius: 30px;
                height: 35px;
            }
            /deep/.el-date-editor .el-range__icon {
                width: 50px;
                font-size: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            /deep/.el-date-editor .el-range-separator {
                //     opacity: 0;
                font-size: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            /deep/.el-date-editor .el-range-input {
                font-size: 15px;
                flex: 1;
                cursor: pointer;
                
            }
            /deep/.el-date-editor .el-range-input::placeholder {
                color: #999;
            }
            .searchData {
                width: 140px;
                height: 35px;
                margin-left: 30px;
                border: none;
                background: linear-gradient(90deg, #305A9E 0%, #0E6FA7 100%);
                border-radius: 50px;
                padding: 0;
                /deep/ span {
                    font-size: 15px !important;
                }
            }
        }
    }

    /deep/.pagination {
        padding: 30px 1%;
        display: flex;
        justify-content: center;
    }
}
</style>